import { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { ContactUpdate } from "../../../api/models/ContactUpdate";
import { ContactRead, ContactsService, NewsSubcription } from "../../../api";
import { newsSubscriptionsUpdateSchema } from "./news-subscriptions-schema";
import * as api from "../../../api/services/ContactsService";
import Loader from "../../_GenericElements/Loader/loader";
import { NewsSubscriptionsFormElement } from "../../../models/umbracoElement";
import React from "react";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";
import { isMobile } from "../../../helpers/MobileHelper";
import { DescribedCheckbox } from "../DescribedCheckbox/described-checkbox";

export interface INewsSubscriptionsFormProps {
    data: string;
}

export const NewsNMagazineDefaultValues : NewsSubcription = {
    newsLettersFromDM : false,
    eventNewsAtDM: false,
    // sectorNewsletter: false,
    // serviceMails: false,
    bioNewsLetter: false,
    receiveResearchForumDigitally: false,
    receiveDMAcademyMagazinePrinted: false,
    receiveDMAcademyMagazinePrintedStud: false,
    receiveMomentumPrinted: false,
    isBioPillar: false,
};

export const GetUpdatedStateWithContact = (newsSubscriptionInfo : NewsSubcription) => {
    let newValues : NewsSubcription= {
        newsLettersFromDM : newsSubscriptionInfo.newsLettersFromDM ?? false,
        eventNewsAtDM: newsSubscriptionInfo.eventNewsAtDM ?? false,
        // sectorNewsletter: newsSubscriptionInfo.sectorNewsletter ?? false,
        // serviceMails: newsSubscriptionInfo.serviceMails ?? false,
        bioNewsLetter: newsSubscriptionInfo.bioNewsLetter ?? false,
        canReceiveResearchForum: newsSubscriptionInfo.canReceiveResearchForum ?? false,
        receiveResearchForumDigitally: newsSubscriptionInfo.receiveResearchForumDigitally ?? false,
        receiveDMAcademyMagazinePrinted: newsSubscriptionInfo.receiveDMAcademyMagazinePrinted ?? false,
        receiveDMAcademyMagazinePrintedStud: newsSubscriptionInfo.receiveDMAcademyMagazinePrintedStud ?? false,
        receiveMomentumPrinted: newsSubscriptionInfo.receiveMomentumPrinted ?? false,
        isBioPillar: newsSubscriptionInfo.isBioPillar ?? false,
        isDigiPillar: newsSubscriptionInfo.isDigiPillar ?? false,
    };
    return newValues;
};

export const NewsSubscriptionsForm = (props: INewsSubscriptionsFormProps) => {
    const [umbracoSettings] = React.useState<NewsSubscriptionsFormElement>(props.data ? JSON.parse(props.data) : {});
    const [isSubmitting, setSubmitting] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [defaultValues, setDefaultValues] = React.useState<NewsSubcription>(NewsNMagazineDefaultValues);

    useEffect(() => {
        void getCurrentData();
    }, []);

    const getCurrentData = async () => {
        setLoading(true);
        const contact = await api.ContactsService.getNewsSubscriptions();

        setDefaultValues(GetUpdatedStateWithContact(contact));
        setLoading(false);
    };

    const updateContact = async (formData: NewsSubcription) => {
        await ContactsService.updateNewsSubscriptions(formData);
    };


    const newsSubscriptionForm = (<>
        <h2>{umbracoSettings.title || ""}</h2>
        <Formik
            initialValues={defaultValues}
            onSubmit={async (values) => {
                setSubmitting(true);
                await updateContact(values);
                setSubmitting(false);
            }}
            validationSchema={newsSubscriptionsUpdateSchema}
            enableReinitialize={true}
        >
            {({ errors, values }) =>
                <Form style={{ textAlign: isMobile ? "center" : "inherit" }}>
                    <DescribedCheckbox<NewsSubcription>
                        name="newsLettersFromDM"
                        label={umbracoSettings.receiveDMAcademyMagLabel || "Nyhedsbrev fra DM"}
                        description={umbracoSettings.receiveDMAcademyMagDescription} />
                    <DescribedCheckbox<NewsSubcription>
                        name="eventNewsAtDM"
                        label={umbracoSettings.eventNewsAtDMLabel || "Arrangementer i DM"}
                        description={umbracoSettings.eventNewsAtDMDescription} />

                    {/* <DescribedCheckbox<NewsSubcription>
                        name="sectorNewsletter"
                        label={umbracoSettings.sectorNewsletterLabel || "Sektor nyhedsbrev"}
                        description={umbracoSettings.sectorNewsletterDescription} /> */}

                    {/* <DescribedCheckbox<NewsSubcription>
                        name="serviceMails"
                        label={umbracoSettings.serviceMailsLabel || "Servicemails"}
                        description={umbracoSettings.serviceMailsDescription} /> */}

                    <DescribedCheckbox<NewsSubcription>
                        name="bioNewsLetter"
                        label={umbracoSettings.bioNyhedsbrevLabel || "Bio søjle"}
                        description={umbracoSettings.bioNyhedsbrevDescription} />

                    <StandardButton type="submit" loading={isSubmitting} disabled={isSubmitting}
                        showArrow={false}>
                        Opdatér
                    </StandardButton>
                </Form>
            }
        </Formik></>);

    return (
        isLoading ?
            <Loader></Loader> :
            <div>
                {newsSubscriptionForm}
            </div>
    );
};