import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { CTACardElement } from "../../models/umbracoElement";
import { ColoredRightArrowIcon } from "../_GenericElements/Icons/Icons";
import { isMobile } from "../../helpers/MobileHelper";
import { ContactsService } from "../../api";
import { useEffect, useState } from "react";

interface CTACardProps extends IconButtonProps {
    data: string;
    disabled?: boolean;
}

/**
 * We need to handle previous KI members so they cant access membership changes until we get to 1-1/2024.
 * Therefore, some states and functions are marked with "Can be deleted at 1-1/2024 and afterwards".
*/

export function CTACard(props: CTACardProps) {
    const elm: CTACardElement = JSON.parse(props.data);

    // Can be deleted at 1-1/2024 and afterwards
    const [disabled, setDisabled] = useState<boolean | undefined>(true);

    // Can be deleted at 1-1/2024 and afterwards
    useEffect(() => {
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        let compareDate = new Date(2024, 0, 1, 0, 0, 0, 0);

        if (currentDate < compareDate) {
            void checkIfCardShouldBeDisable();
        } else {
            setDisabled(false);
        }

    }, []);

    // Can be deleted at 1-1/2024 and afterwards
    const checkIfCardShouldBeDisable = async () => {
        switch (elm.title) {
            case "Arbejde":
                await getIfPreviousKIMember();
                break;
            case "Barsel":
                await getIfPreviousKIMember();
                break;
            case "Ny arbejdstid":
                await getIfPreviousKIMember();
                break;
            case "Ledig/orlov":
                await getIfPreviousKIMember();
                break;
            case "Selvstændig/ freelance":
                await getIfPreviousKIMember();
                break;
            case "Pension/efterløn":
                await getIfPreviousKIMember();
                break;
            default:
                setDisabled(false);
        }
    };

    // Can be deleted at 1-1/2024 and afterwards
    const getIfPreviousKIMember = async () => {
        let isPreviousKIMember: boolean = false;
        try {
            isPreviousKIMember = await ContactsService.getIsPreviousKiMember();

        } catch (err) {
            console.error(err);
        } finally {
            setDisabled(isPreviousKIMember);
        }
    };

    const redirect = () => {
        if (elm.callToActionLink?.target == "_blank"){
            window.open(
                elm.callToActionLink.url,
                elm.callToActionLink?.target
            );
        } else {
            window.location.href = elm.callToActionLink.url;
        }

    };

    let cardActionArea = <CardActionArea onClick={redirect}>
        <CardHeader
            title={<>{elm.title} {ColoredRightArrowIcon("#222")}</>}
        />
    </CardActionArea>;
    if (disabled ? disabled : props.disabled) {
        cardActionArea = <CardActionArea disabled={disabled ? disabled : props.disabled} disableRipple={disabled ? disabled : props.disabled} disableTouchRipple={disabled ? disabled : props.disabled}>
            <CardHeader
                title={<>{elm.title}</>}
            />
        </CardActionArea>;
    }
    return (
        <Card sx={{ marginBottom: isMobile ? "20px" : 0, maxWidth: isMobile ? "760px" : "345px" }}>
            {elm.image ? <CardMedia
                component="img"
                height={elm.image.umbracoHeight}
                image={elm.image._url}
                alt={elm.image.name}
            /> : <div></div>}

            {cardActionArea}
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    {elm.body}
                </Typography>
            </CardContent>

        </Card>
    );
}