/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useMemo, useRef, useState } from "react";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { ApiError, ContactsService, CrmEntity, MainEducationUpdate, MetadataService } from "../../../api";
import { educationFormValues, educationCreateSchema, educationFormLabels } from "./EducationSchema";
import { ApiErrorBody } from "../../../models/apiError";
import { GenericFormElements } from "../../_GenericElements/_GenericFormElements/GenericFormElements";
import { format } from "date-fns";
import formStyles from "../../_GenericElements/_GenericFormElements/form.module.scss";
import { UpdateConfirmMessageBox } from "../UpdateConfirmMessageBox/UpdateConfirmMessageBox";
import { frontPage } from "../../../constants/common-constants";

const defaultFormValues: educationFormValues = {
    id: undefined,
    graduateDate: new Date(),
    expectedGraduateDate: new Date(),
    type: undefined,
    course: undefined,
    placeOfStudy: { id: "", primaryName: "" },
    finishedEducation: true,
    disced: undefined,
    levelOfEducation: MainEducationUpdate.levelOfEducation.BACHELOR,
    educationField: {id: "", primaryName: ""},
    isStudent: true,
  isPreviousKIMember: false,
    furtherEducation: "",
};

const EducationForm = (props: { organisation?: string }) => {

    const [isLoadingPlaces, setLoadingPlaces] = useState(true);
    const [isLoadingEducationFields, setLoadingEducationFields] = useState(false);
    const [originalEducation, setOriginalEducation] = useState<MainEducationUpdate>();
    const [educationPlaces, setEducationPlaces] = useState<CrmEntity[]>([]);
    const [currentEducation, setCurrentEducation] = useState<educationFormValues>(defaultFormValues);
    const [errorMsg, setErrorMsg] = useState<string>("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [educationLevelValue, setEducationLevelValue] = useState<MainEducationUpdate.levelOfEducation | undefined>(undefined);
    const [educationFields, setEducationFields] = useState<CrmEntity[]>([]);

    const formikRef = useRef<FormikProps<educationFormValues>>(null);

    useEffect(() => {
        if (educationLevelValue != undefined) {
            if (educationLevelValue != currentEducation.levelOfEducation) {
                formikRef.current?.setFieldValue("educationField", {id: "", primaryName: ""});
            }
            void getEducationFields(educationLevelValue);
        }
    }, [educationLevelValue]);

    useEffect(() => {
        if (formikRef.current != null) {
            formikRef.current.setFieldValue("levelOfEducation", undefined);
        }
    }, [formikRef]);

    const getEducationFields = async (educationlevel: MainEducationUpdate.levelOfEducation) => {
        setLoadingEducationFields(true);
        let educationFields = await MetadataService.getFilteredEducationFields(educationlevel.toString());
        setEducationFields(educationFields);
        setLoadingEducationFields(false);
    };

    const getEducationPlaces = async () => {
        let places = await MetadataService.getAllPlacesOfStudy();
        setEducationPlaces(places);
        setLoadingPlaces(false);
    };

    const getCurrentEducation = async () => {
        let education: MainEducationUpdate | undefined = undefined;
        try {
            education = await ContactsService.getMainEducation();
        } catch (err) {
            if (err instanceof ApiError) {
                if (err.status == 404) {
                    setErrorMsg("Primær uddannelse mangler");
                }
            }
        } finally {
            const isPreviousKIMember = await getIsPreviousKIMember();
            const formval = education && education.disced ? {
                ...defaultFormValues,
                id: education.id,
                graduateDate: education.graduateDate ? new Date(education.graduateDate) : new Date(),
                expectedGraduateDate: education.expectedGraduateDate ? new Date(education.expectedGraduateDate) : undefined,
                disced: education.disced,
                levelOfEducation: education.levelOfEducation,
                educationField: education.educationField,
                placeOfStudy: education.placeOfStudy,
                finishedEducation: !education.isStudent ?? true,
                isStudent: education.isStudent,
                isPreviousKIMember: isPreviousKIMember,
                furtherEducation: education.furtherEducation,
            } : {...defaultFormValues, levelOfEducation: undefined, isStudent: education?.isStudent, finishedEducation: !education?.isStudent ?? true, isPreviousKIMember: isPreviousKIMember};

            setCurrentEducation(formval);
            setOriginalEducation(education);

            if (education?.levelOfEducation != null || education?.levelOfEducation != undefined) {
                setEducationLevelValue(education.levelOfEducation);
            }
        }
    };

    const getIsPreviousKIMember = async () => {
        try {
            return await ContactsService.getIsPreviousKiMember();
        } catch (e) {
            console.error(e);
            return false;
        }
    };

    useEffect(() => {
        void getEducationPlaces();
        void getCurrentEducation();
    }, []);

    const FormElements = new GenericFormElements<educationFormValues>(educationFormLabels);
    const createOrUpdate = async (formData: educationFormValues) => {

        let stringifiedFormData: MainEducationUpdate = {
            ...formData,
            graduateDate: formData.finishedEducation && formData.graduateDate ? formData.graduateDate.toISOString() : null,
            expectedGraduateDate: !formData.finishedEducation && formData.expectedGraduateDate ? format(formData.expectedGraduateDate, "yyyy-MM-dd") : null,
            placeOfStudy: formData.placeOfStudy,
            educationField: formData.educationField,
            levelOfEducation: formData.levelOfEducation,
            disced: formData.disced,
            furtherEducation: formData.furtherEducation,
        };

        if (stringifiedFormData.educationField && originalEducation?.educationField) {
            if (stringifiedFormData.levelOfEducation != originalEducation.levelOfEducation) {
                // type is not the same as original. Making sure that id is emptied, so that the new entity will be created.
                stringifiedFormData.id = undefined;
            } else {
                // otherwise making sure that id is set, so that the entity will updated.
                stringifiedFormData.id = originalEducation.id;
            }
        }

        try {
            await ContactsService.createNewMaineducation(stringifiedFormData);
            setShowConfirm(true);
        } catch (e) {
            if (e instanceof ApiError) {
                if (e.status == 400) {
                    let errorInfo: ApiErrorBody = JSON.parse(e.body);
                    setErrorMsg(errorInfo.title);
                }
            }
        }
    };

    const resetEducation =
    (
        educationLevel: MainEducationUpdate.levelOfEducation | undefined | null,
        expectedGraduateDate: Date | undefined,
        graduateDate: Date | undefined,
        placeOfStudy: CrmEntity | undefined,
        educationField: CrmEntity | undefined,
        finishedEducation: boolean,
        furtherEducation: string | undefined | null,
    ) => {
        const educationCreate: educationFormValues =
        { ...defaultFormValues,
            levelOfEducation: educationLevel,
            expectedGraduateDate: expectedGraduateDate,
            graduateDate: graduateDate,
            placeOfStudy: placeOfStudy,
            educationField: educationField,
            disced: undefined,
            id: undefined,
            isStudent: currentEducation.isStudent,
            finishedEducation: finishedEducation,
          isPreviousKIMember: currentEducation.isPreviousKIMember,
            furtherEducation: furtherEducation,
        };
        setCurrentEducation(educationCreate);
    };

    const onCloseConfirmMessage = () => {
        setShowConfirm(false);
        window.location.href = frontPage;
    };

    const educationForm = useMemo(() => {
        return props.organisation && <Formik
            initialValues={currentEducation}
            enableReinitialize={true}
            innerRef={formikRef}
            validate={(values) => {

                type errors = {
                    placeOfStudy: undefined | string,
                    educationField: undefined | string,
                    levelOfEducation: undefined | string,
                };


                let anyErrors = false;
                const errorList: errors = {
                    placeOfStudy: undefined,
                    educationField: undefined,
                    levelOfEducation: undefined,
                };

                if (values.levelOfEducation != currentEducation.levelOfEducation) {
                    resetEducation(values.levelOfEducation, values.expectedGraduateDate, values.graduateDate, values.placeOfStudy, undefined, values.finishedEducation, values.furtherEducation);
                }

                if (!values.educationField || !values.educationField.id) {
                    errorList.educationField = "Fagområde skal angives.";
                    anyErrors = true;
                }

                if (!values.placeOfStudy || !values.placeOfStudy.id) {
                    errorList.placeOfStudy = "Uddannelsessted skal angives.";
                    anyErrors = true;
                }

                if (!values.levelOfEducation) {
                    errorList.levelOfEducation = "Niveau skal angives.";
                    anyErrors = true;
                } else if (educationLevelValue != values.levelOfEducation) {
                    setEducationLevelValue(values.levelOfEducation);
                }

                if (anyErrors) {
                    return errorList;
                }

                return {};
            }}

            onSubmit={async (values, { setSubmitting}) => {
                setSubmitting(true);
                await createOrUpdate(values);
                setSubmitting(false);
            }}
            validationSchema={educationCreateSchema}
        >
            {({
                errors,
                values,
                isSubmitting,
                handleSubmit,
            }) =>

                <Form className={formStyles.dmForm} onSubmit={handleSubmit}>
                    {currentEducation.isStudent ?
                        <FormElements.BasicDateInput  formValueName="expectedGraduateDate" /> :
                        <FormElements.BasicDateInput  formValueName="graduateDate" />
                    }

                    {FormElements.EnumDropDown("levelOfEducation", MainEducationUpdate.levelOfEducation)}

                    <FormElements.RecordAutoComplete disabled={values.levelOfEducation == undefined || isLoadingEducationFields || isSubmitting}  formValueName="educationField" options={educationFields}
                        loading={isLoadingEducationFields} />

                    <FormElements.RecordAutoComplete  formValueName="placeOfStudy" options={educationPlaces}
                loading={isLoadingPlaces} />

                    <h2>Videreuddannelse</h2>
                    <FormElements.BasicTextInput  formValueName="furtherEducation"/>

                    {FormElements.SubmitButton({ type: "submit", loading: isSubmitting, disabled: isSubmitting })}
                </Form>
            }
        </Formik >;
    }, [currentEducation, isLoadingPlaces, isLoadingEducationFields]);

    return (
        <div>
            <h2>Uddannelsesniveau og fagområde</h2>
            {educationForm}
            {showConfirm && <UpdateConfirmMessageBox onClose={() => onCloseConfirmMessage()} title="Uddannelse ændring" />}
        </div>
    );
};

export default EducationForm;